<template>
  <div id="CommonCrumbTab">
    <!-- Breadcrumb 面包屑 Tabs 标签页 -->
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="headerInfo.path">{{headerInfo.label}}</el-breadcrumb-item>
      <!-- <span @click="aaa">13211</span> -->
    </el-breadcrumb>
    <tab :Systemvalue='Systemvalue'></tab>

  </div>
</template>

<script>

import Tab from 'components/CommonTab.vue'

import { mapState } from 'vuex'
export default {
  components: {
    Tab
  },
  props: {
    Systemvalue: Number
  },
  computed: {
    ...mapState({
      current: state => state.tab.currentMenu,
    })
  },
  watch: {
    current (newC) {
      this.headerInfo = newC
    },
    Systemvalue () {
      this.headerInfo = []
    }
  },
  data () {
    return {
      headerInfo: [],//头部信息
    }
  },
  methods: {
    aaa () {
      var aa = ''
      aa = this.current.label
      console.log(aa)
    }
  }

}
</script>

<style lang="scss" scoped>
#CommonCrumbTab {
  padding-top: $padding-15;
  .el-breadcrumb {
  }
  .CommonMainCon {
  }
}
</style>
