<template>
  <!-- Tabs 标签页 -->
  <div id="commonTab">
    <el-tag v-for="tag in tabs"
            :key="tag.name"
            :closable="tag.name !=='Home'"
            :disable-transitions="false"
            size="small"
            @close="handleClose(tag)"
            @click="changeMenu(tag)"
            :effect="$route.name == tag.name? 'dark':'plain'">
      {{tag.label}}
    </el-tag>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    Systemvalue: Number
  },
  computed: {
    ...mapState({
      tabs: state => state.tab.tabsList
    })

  },
  data () {
    return {
      inputVisible: false,
      inputValue: '',
      tabsInfo: [],
    }
  },
  watch: {
    tabs (newC) {
      this.tabsInfo = newC
      console.log(this.tabsInfo, "傲视")
    },
    Systemvalue (newv) {
      console.log(newv)
      this.tabsInfo = []
      console.log(this.tabsInfo)
    }
  },
  methods: {
    ...mapMutations({
      close: 'closeTab'
    }),
    // 关闭tab标签
    handleClose (tab) {
      this.close(tab)
    },
    // 点击tab标签跳转路径
    changeMenu (item) {
      this.$router.push({ name: item.name })
      this.$store.commit('selectMenu', item)
    }
  }
}
</script>

<style lang="scss" scoped>
#commonTab {
  margin-top: $margin-10;
  padding: 3px 0;
  border-top: 1px solid $aside-back-color;
  border-bottom: 1px solid $aside-back-color;
  cursor: pointer;

  .el-tag {
    margin-right: 10px;
    border-radius: 0;
    height: 100%;
  }
}
</style>
